import {Controller} from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {

  static values = {
    tags: Boolean
  }

  connect() {
    this.element.classList.remove("form-select");
    this.element.classList.add("form-control");

    const options = {
      select: this.element,
      settings: {
        allowDeselect: !this.element.required,
        closeOnSelect: this.single,
        hideSelected: true,
        placeholderText: this.element.getAttribute('placeholder'),
      },
      events: {}
    }

    if (this.tagsValue) {
      options.events.addable = (value) => { return value }
    }

    this.slimselect = new SlimSelect(options);
  }

  disconnect() {
    if (this.slimselect) {
      this.slimselect.destroy()
    }
  }

  get single() {
    return !this.element.multiple
  }

  get multi() {
    return this.element.multiple
  }

  get inModal() {
    return this.element.closest('.modal-dialog') != null
  }

}