// Adapted from http://jsfiddle.net/_nderscore/QTQ5s/
// and https://stackoverflow.com/questions/20689481/recreating-jquerys-ajaxstart-and-ajaxcomplete-functionality

/* kjax AJAX listeners
 *
 * Non-jQuery event listeners for XHR requests
 *
 * kjax.onAjaxStart(func)
 * kjax.onAjaxComplete(func)
 *
 * func  = function to execute
 */
const kjax = {};

(function () {
    if (kjax._preAjaxHandlers) return;

    kjax._preAjaxHandlers = [];
    kjax._postAjaxHandlers = [];

    kjax.onAjaxStart = function (handler) {
        kjax._preAjaxHandlers.push(handler);
    };

    kjax.onAjaxComplete = function (handler) {
        kjax._postAjaxHandlers.push(handler);
    };

    function firePreAjaxEvents (request) {
        kjax._preAjaxHandlers.forEach( h => h(request) );
    }

    function firePostAjaxEvents (request) {
        kjax._postAjaxHandlers.forEach( h => h(request) );
    }

    const send = XMLHttpRequest.prototype.send;

    XMLHttpRequest.prototype.send = function () {
        firePreAjaxEvents(this);

        this.addEventListener('loadend', function () {
            firePostAjaxEvents(this);
        }, false);

        return send.apply(this, arguments);
    };

    document.addEventListener('fetchStart', function () {
        firePreAjaxEvents(this);
    });

    document.addEventListener('fetchEnd', function () {
        firePostAjaxEvents(this);
    });

})();

export default kjax