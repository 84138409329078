import iziToast from 'izitoast'

export function showMessage(type, message) {
  return iziToast[type]({
    message: message,
    position: 'topCenter',
    timeout: (type === 'error') ? 0 : 5000
  });
}

export function showErrors(errors) {
  errors.forEach(e => showMessage('error', e));
}

export function ajaxFail(jqXHR) {
  console.log('ajaxFail', 'jqXHR', jqXHR);
  const json = jqXHR.responseJSON;
  if (!json) return;
  if (json.errors) showErrors(json.errors);
  if (json.error) showMessage('error', json.error);
}