import {Controller} from '@hotwired/stimulus'
import kjax from '../utils/kjax'

export default class extends Controller {

  connect() {
    this.hideSpinner();
    this.boundShowSpinner = this.showSpinner.bind(this);
    this.boundHideSpinner = this.hideSpinner.bind(this);

    kjax.onAjaxStart(this.boundShowSpinner);
    kjax.onAjaxComplete(this.boundHideSpinner);
    window.addEventListener('beforeunload', this.boundShowSpinner);
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.boundShowSpinner);
  }

  hideSpinner() {
    this.element.style.opacity = '0';
  }

  showSpinner() {
    this.element.style.opacity = '100';
  }

}




