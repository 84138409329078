import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["input", "list"];

  addItem(ev) {
    ev.preventDefault();
    const lastInput = this.inputTargets[this.inputTargets.length - 1].cloneNode();
    lastInput.value = '';
    this.listTarget.appendChild(lastInput);
  }

}