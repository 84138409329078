import {Controller} from '@hotwired/stimulus'
import * as Messages from '../utils/messages'

export default class extends Controller {

  static values = {
    type: String,
    message: String
  }

  connect() {
    Messages.showMessage(this.typeValue, this.messageValue);
    this.element.remove();
  }

}