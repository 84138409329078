import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

document.addEventListener('DOMContentLoaded', createPopovers);
document.addEventListener('remoteComplete', createPopovers);

function createPopovers() {
  document.querySelectorAll('[data-bs-toggle="popover"]').forEach(e => {
    bootstrap.Popover.getOrCreateInstance(e, {trigger: 'hover click focus'});
  })
}