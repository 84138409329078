import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.boundFormSubmit = this.formSubmit.bind(this)
    this.element.addEventListener('change', this.boundFormSubmit)
  }

  disconnect() {
    this.element.removeEventListener('change', this.boundFormSubmit)
  }

  formSubmit() {
    const form = this.element.closest('form')
    Rails.fire(form, 'submit');
    if (!form.getAttribute('data-remote')) {
      form.submit();
    }
  }

}