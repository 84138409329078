import SweetAlert from 'sweetalert2'
import escape from 'escape-html'
import Rails from "@rails/ujs";

// Based on solution found here: https://derk-jan.com/2020/10/rails-ujs-custom-confirm/

let skipConfirmation = false;

// Override the default confirm dialog by rails
Rails.confirm = function (message, element) {
  if (skipConfirmation) return true;

  const text = element.getAttribute('data-text');
  const targetSelector = element.getAttribute('data-confirm-input-target');
  let target;
  let fileExt;
  if (targetSelector) {
    target = document.querySelector(targetSelector);
    fileExt = target.value.match(new RegExp('\.[0-9a-z]+$', 'i'))[0];
  }

  let alertOptions = {
    title: escape(message),
    text: escape(text || ''),
    icon: 'warning',
    confirmButtonText: 'Yes',
    showCancelButton: true,
    cancelButtonText: 'No'
  }

  if (target) {
    delete alertOptions.confirmButtonText;
    delete alertOptions.cancelButtonText;
    delete alertOptions.icon;
    alertOptions.input = 'text';
    alertOptions.inputValue = target.value;
  }

  SweetAlert.fire(alertOptions).then(function (result) {
    if (result.value) {
      if (target) {
        if (result.value.toLowerCase().endsWith(fileExt.toLowerCase())) {
          target.value = result.value;
        } else {
          target.value = result.value + fileExt;
        }
      }

      // Temporarily disable confirmation dialog and perform the action
      skipConfirmation = true;
      element.click();
      skipConfirmation = false;
    }
  });

  return false;
}